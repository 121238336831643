.how-work {
  padding: 70px 0;
  background: url("../../assets/images/kitchen.webp") no-repeat center center;
  background-size: cover;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
}

.how-work__title {
  margin: 0;
  position: relative;
  z-index: 2;
  color: #FFFFFF;
  font-size: 40px;
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    background-color: #FF7A00;
    width: 203px;
    height: 2px;
    z-index: 3;
    left: 0;
    bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    background-color: #FF7A00;
    width: 203px;
    height: 2px;
    z-index: 3;
    bottom: 0;
    right: 0;
  }
}

.how-work__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.how-work__item {
  max-width: 407px;
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  z-index: 2;
}

.how-work__text {
  margin: 0;
  color: #FFFFFF;
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: normal;

}

.how-work__number {
  color: #ff7a0080;
  font-size: 60px;
  margin: 0;
  letter-spacing: 3px;
  font-weight: 400;
}

@media only screen and (max-width: 992px) {
  .how-work__list {
    gap: 30px;
  }
  
  .how-work__item {
    gap: 5px;
    max-width: 320px;
  }

  .how-work__text {
    font-size: 14px;
  }

  .how-work__number {
    font-size: 40px;
  }

  .how-work__title {
    font-size: 35px;
  
    &::before,&::after {
      width: 140px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .how-work__list {
    gap: 15px;
    align-items: start;
    justify-content: flex-start;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .how-work__item {
    max-width: 400px;
  }

  .how-work__text {
    font-size: 16px;
  }

  .how-work__number {
    font-size: 33px;
  }

  .how-work__title {
    font-size: 28px;
  
    &::before,&::after {
      width: 80px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .how-work__title {
    font-size: 23px;
  
    &::before,&::after {
      width: 50px;
    }
  }

  .how-work__number {
    font-size: 27px;
  }
}

@media only screen and (max-width: 550px) {
  .how-work__title {
    font-size: 20px;
  
    &::before,&::after {
      width: 25px;
    }
  }

  .how-work__number {
    font-size: 25px;
  }

  .how-work__text {
    font-size: 14px;
  }
}