.contact-us {
  height: 100vh;
  padding-top: 200px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.664);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    background: url("https://i.pinimg.com/originals/88/66/1b/88661b8c765b41887df3314cb5d3dd50.jpg") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0px;
    // background-attachment: fixed;
  }
}

.contact-us__content {
  position: relative;
  z-index: 4;
}

.contact-us__title {
  margin: 0;
  color: #FFF;
  text-align: center;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 50px;
  letter-spacing: 2px;
}

.contact-us__text {
  margin: 0;
  color: #FFF;
  text-align: center;
  font-size: 24px;
  padding: 0 100px;
}

.contact__form {
  margin-top: 200px;
  display: block;
}



@media only screen and (max-width: 900px) {
  .contact-us__title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 750px) {
  .contact-us__title {
    font-size: 30px;
  }

  .contact-us {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 570px) {
  .contact-us__text {
    padding: 0 40px;
  }

  .contact-us__title{
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 470px) {
  .contact-us__title {
    font-size: 28px;
  }

  .contact-us__text {
    padding: 0 10px;
    // line-height: 1.2;
    font-size: 20px;
  }

  .contact-us {
    padding-top: 130px;
  }
}