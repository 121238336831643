.swiper__wrapper {
  width: 320px;
  height: 320px;
  position: relative;
  z-index: 4;
}

.about-us {
  padding: 70px 0;
  background: url("../../assets/images/kitchen1.webp") no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
}

.about-us__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 300px;
}

.about-us__text-wrapper {
  position: relative;
  z-index: 3;
}

.about-us__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.about-us__title {
  color: #FF7A00;
  font-size: 40px;
  letter-spacing: 3.5px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.about-us__item {
  margin-bottom: 33px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    width: 488px;
    height: 2px;
    background-color: #FF7A00;
    bottom: -12px;
    left: 0;
  }
}

.about-us__item-text {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #FFF;
}

.swiper__content {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border: #FF7A00 solid 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
  margin: 0 auto;
}

.swiper__more {
  margin: 0;
  font-size: 18px;
  color: #FFF;
  line-height: normal;
  letter-spacing: 0.9px;
}

.swiper__number {
  margin: 20px 0 30px;
  font-size: 100px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 5px;
  color: #FF7A00;
}

.swiper__projects {
  margin: 0;
  font-size: 18px;
  color: #FFF;
  line-height: normal;
  letter-spacing: 0.9px;
}

@media only screen and (max-width: 1068px) {
  .about-us__container {
    justify-content: space-between;
    gap: 0;
  }
}

@media only screen and (max-width: 930px) {
  .swiper__content {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    padding-top: 30px;
  }

  .swiper__number {
    margin: 20px 0 25px;
    font-size: 70px;
  }

  .about-us__item {
    &::before {
      width: 420px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .swiper__content {
    width: 240px;
    height: 240px;
    padding-top: 30px;
    margin-top: 30px;
  }

  .swiper__number {
    margin: 20px 0 25px;
    font-size: 50px;
  }

  .about-us__item {
    &::before {
      width: 380px;
    }
  }

  .about-us__item-text {
    font-size: 18px;
  }

  .swiper__projects{
    font-size: 16px;
  }
}

@media only screen and (max-width: 770px) {
  .about-us__item-text {
    font-size: 16px;
  }

  .about-us__item {
    margin-bottom: 20px;
    &::before {
      width: 340px;
    }
  }
}

@media only screen and (max-width: 730px) {
  .swiper__wrapper{
    width: 270px;
    height: 270px;
  }
}

@media only screen and (max-width: 670px) {
  .about-us__item {
    margin-bottom: 20px;
    &::before {
      width: 100%;
    }
  }
  .about-us__container{
    flex-direction: column;
  }

  .about-us__title{
    font-size: 35px;
    text-align: center;
  }

  .about-us__text-wrapper{
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 400px) {
 

  .swiper__content {
    width: 200px;
    height: 200px;
    padding-top: 10px;
    margin-top: 30px;
  }

  .swiper__number {
    margin: 20px 0 15px;
    font-size: 50px;
  }

  .swiper__projects{
    width: 152px;
    text-align: center;
  }
}