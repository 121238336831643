.swiper {
  width: 500px;
  height: 350px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: #FF7A00;
}

.swiper-pagination-bullet {
  background-color: #FF7A00 !important;
}

@keyframes bullet-animation {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.2);
  }
}

.swiper-pagination-bullet-active {
  background-color: #FF7A00 !important;
  height: 10px !important;
  width: 20px !important;
  border-radius: 10px !important;
  transform: scale(1.1);
  animation: ease bullet-animation 3s;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.intro-section {

  height: 100vh;
  padding-top: 150px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.664);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    background: url("https://i.pinimg.com/originals/88/66/1b/88661b8c765b41887df3314cb5d3dd50.jpg") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0px;
    // background-attachment: fixed;
  }
}

.intro-section__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.intro-section__content {
  padding-right: 120px;
}

.intro-section__text {
  margin: 0;
  color: #FFFFFF;
  font-size: 37px;
  position: relative;
  z-index: 1;
  line-height: 1.4;
  font-weight: 600;
}

.intro-section__description {
  margin: 0;
  color: #FFFFFF;
  font-size: 25px;
  margin: 20px 0;
  position: relative;
  z-index: 1;
}

.intro-section__swiper-wrapper {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-size: cover;
    z-index: 0;
    width: 681px;
    height: 420px;
    flex-shrink: 0;
    top: -35px;
    left: 150px;
    border: 10px solid var(--unnamed, #FF7A00);
    background: rgba(196, 196, 196, 0.00);
    z-index: 1;
  }
}

.intro-section__consultatoin-btn {

  position: relative;
  color: white;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 4px solid #FF7A00;
  border-radius: 1000px;
  font-size: 18px;
  padding: 10px 20px;
  box-shadow: 0 2px 5px 0 rgba(3, 6, 26, 0.15);
  // margin: 20px 0;
  transition: 0.5s all ease-in-out;
  animation: pulse 1.5s infinite 0.2s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0 0 #FF7A00;
  z-index: 2;
  transform: translateY(20px);

  &:hover {
    cursor: pointer;
    background: white;
    color: #1F4141;
    animation: none;
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 255, 255, 0);
  }
}

.intro-section__notification {
  position: relative;
}

.intro-section__notification-container {
  background-color: rgba(29, 23, 21, 0.884);
  padding: 5px 10px 7px;
  border-radius: 30px;
  position: absolute;
  z-index: 1;
  width: 100%;

  border: #FF7A00 solid 2px;
  // transform: translate(50vh, 130px);
  bottom: -150px;
  left: 50vh;

  &::before {
    content: '';
    position: absolute;
    background: url('../../assets/icons/notification.png') center center no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    z-index: 2;
    left: 0;
    top: -15px;
  }

  &::after {
    background: #f8d471;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    content: "";
    -webkit-filter: blur(10.9364px);
    filter: blur(10.9364px);
    height: 2.5vw;
    left: -15px;
    opacity: .7;
    position: absolute;
    top: -10px;
    width: 4.5vw;
    // z-index: 2;
  }
}

.intro-section__notification-text {
  color: #FFFFFF;
  font-size: 22px;
  text-align: center;
  margin: 0;
  line-height: 1.6;
}


@media only screen and (max-width: 1170px) {
  .swiper {
    width: 450px;
    height: 320px;
  }

  .intro-section__text {
    font-size: 34px;
    line-height: 1.3;
  }

  .intro-section__swiper-wrapper {
    position: relative;

    &::before {
      content: '';
      height: 390px;
      border: 8px solid var(--unnamed, #FF7A00);
    }
  }

  .intro-section__notification-text {
    font-size: 20px;
  }

  .intro-section__consultatoin-btn {
    padding: 7px 15px;
  }
}

@media only screen and (max-width: 1061px) {
  .intro-section__notification-container {
    width: 500px;
    bottom: -150px;
    left: calc(50vh - 70px);
  }
}

@media only screen and (max-width: 990px) {
  .swiper {
    width: 420px;
    height: 320px;
  }

  .intro-section__text {
    font-size: 32px;
    line-height: 1.2;
  }

  .intro-section__description {
    font-size: 22px;
    margin: 15px 0;
  }

  .intro-section__notification-container {
    width: 500px;
    bottom: -150px;
    left: 30vh;
  }
}

@media only screen and (max-width: 900px) {
  .swiper {
    width: 400px;
    height: 300px;
  }

  .intro-section__swiper-wrapper::before {
    height: 370px;
  }

  .intro-section__notification-container {
    left: 23vh;
  }

  .intro-section__consultatoin-btn {
    font-size: 16px;
  }

  .intro-section__content {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .swiper {
    width: 370px;
    height: 270px;
  }

  .intro-section__swiper-wrapper::before {
    height: 340px;
  }

  .intro-section__text {
    font-size: 28px;
  }

  .intro-section__notification-container {
    left: 18vh;
  }
}

@media only screen and (max-width: 750px) {

  .intro-section__container {
    flex-direction: column;
  }

  .intro-section {
    height: calc(100vh + 300px);  
  }

  .intro-section__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .intro-section__text {
    text-align: center;
  }

  .intro-section__swiper-wrapper{
    margin-top: 105px;
  }

  .intro-section__notification-container {
    bottom: 0;
    left: 0;
    position: relative;
    transform: translateY(40px);
  }
}

@media only screen and (max-width: 520px) {
  .intro-section__notification-text {
    font-size: 18px;
  }

  .intro-section__notification-container {
    transform: translateY(40px);
    width: 410px;
  }
}

@media only screen and (max-width: 430px) {
  .intro-section__text{
    font-size: 24px;
  }

  .intro-section__consultatoin-btn {
    font-size: 16px;
    padding: 5px 8px;
    transform: translateY(10px);
  }

  .intro-section__notification-text {
    font-size: 16px;
  }

  .intro-section__notification-container {
    transform: translateY(40px);
    width: 350px;
    padding: 5px;

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      left: -5px;
      top: -25px;
    }
  }
}

@media only screen and (max-width: 392px) {
  .swiper {
    width: 320px;
    height: 220px;
  }

  .intro-section__description{
    text-align: center;
  }

  .intro-section__swiper-wrapper::before {
      width: 681px;
      height: 290px;
      top: -35px;
      left: 150px;
    }
}

@media only screen and (max-width: 370px) {
  .intro-section__notification-text {
    font-size: 16px;
    line-height: 1.1;
  }

  .intro-section__notification-container {
    width: 300px;
    margin-left: 10px;
  }
}