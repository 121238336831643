// html {
//   overflow-y: scroll;
//   height: 100%;
//   -webkit-overflow-scrolling: touch;
//   // overflow-scrolling: touch;
// }

// body {
//   overflow-y: visible;
//   position: relative;
//   overflow-x: hidden;
// }

// html,
// body {
//   margin: 0;
// }

// .container-g {
//   width: 600vw;
//   height: 100vh;
//   display: flex;
//   flex-wrap: wrap;
//   // padding-top: 100px !important;
// }

// .firstContainer {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background: yellow;
// }

// .lastContainer {
//   display: flex;
//   height: 100vh;
//   background: yellow;
// }

// .panel {
//   width: 100vw;
//   height: 100vh;
// }

// .red {
//   background: blue;
// }

// .red {
//   background: red;
// }

// .orange {
//   background: orange;
// }

// .purple {
//   background: purple;
// }

// .blue {
//   background-color: #000;
// }

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.gallery {
 background-color: #ffffff;
 padding: 100px 0;
}

.gallery__title {
  margin: 0;
  text-align: center;
  margin-top: 90px;
  color: #000000;
  margin-bottom: 100px;
}

.gale{
  width: 300px;
  transition: 4s ease all;
  animation: heartbeat 4s infinite;
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 3;
}

.gale::before,
.gale::after{
    position: absolute;
    content: "";
    left: 50px;
    top: 0;
    width: 50px;
    height: 80px;
    background: #fff;
    -moz-border-radius: 50px 50px 0 0;
    border-radius: 50px 50px 0 0;
    -webkit-transform: rotate(-45deg);
       -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
         -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
       -moz-transform-origin: 0 100%;
        -ms-transform-origin: 0 100%;
         -o-transform-origin: 0 100%;
            transform-origin: 0 100%;
}
.gale::after {
    left: 0;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
       -moz-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
         -o-transform-origin: 100% 100%;
            transform-origin :100% 100%;
}

// .gallery__img-wrapper {
//   display: flex;
// }

// .gallery__img1 {
//   width: 300px;
//   position: relative;
//   border-radius: 10px;
//   transition: .3s ease all;

//   &:hover{
//     transform: scale(1.1);
//   }
// }

// .gallery__square {
//   position: relative;
//   margin-top: 100px;
//   margin-left: 200px;

//   &::before {
//     content: '';
//     position: absolute;
//     width: 300px;
//     height: 300px;
//     // background-color: red;
//     border: #FF7A00 solid 4px;
//     right: -20px;
//     bottom: -20px;
//     z-index: 0;
//     border-radius: 10px;
//   }
// }

// .gallery__square > div {
//   overflow: hidden;
//   border-radius: 10px;
// }

// .gallery__img2 {
//   width: 300px;
//   position: relative;
//   border-radius: 10px;
//   transition: .3s ease all;

//   &:hover{
//     transform: scale(1.1);
//   }
// }

// @media only screen and (max-width: 800px) {
//   .gallery__img-wrapper {
//     display: flex;
//     flex-direction: column;
//   }
// }

// .swiper__flip {
//   width: 70% !important;
// }

// .swiper-slide {
//   background-position: center;
//   background-size: cover;
//   width: 300px;
//   height: 300px;
// }


.swiper__loop {
  width: 100%;
  height: calc(100vh - 150px) !important;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide-loop {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper1 {
  height: 20% !important;
  box-sizing: border-box !important;
  padding: 10px 0 !important;
}

.mySwiper1 .swiper-slide-loop {
  width: 25% !important;
  height: 100% !important;
  opacity: 0.4 !important;
}

.mySwiper1 .swiper-slide-thumb-active {
  opacity: 1 !important;
}
  
.swiper-slide-loop img {
  display: block !important;
  width: 80% !important;
  height: 100% !important;
  object-fit: contain !important;
  margin: 0 auto !important;
}
