.calculator {
  position: relative;
  padding: 100px 0;

  &::before {
    content: '';
    position: absolute;
    background: url("../../assets/images/calculatorformbg.webp") center center no-repeat;
    background-size: cover;
    width: 60%;
    height: 100%;
    top: 0px;
  }

  &::after {
    content: '';
    position: absolute;
    background: url("../../assets/images/calculatorbg.webp") center center no-repeat;
    background-size: cover;
    width: 40%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;

  }
}



.calculator__content {
  display: flex;

  &::before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.808);
    background-size: cover;
    width: 60%;
    height: 100%;
    top: 0px;
    left: 0;
  }
}

.calculator__form-wrapper {
  max-width: 600px;
  position: relative;
  z-index: 3;
}

.calulator__title {
  margin: 0;
  color: #fff;
  font-size: 30px;
  margin-bottom: 100px;
}

.calculator__form-content {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
}

// .calculator__select {}

// .calculator__label {}

// .calculator__input {}

.calculator__value {
  color: #fff;
  margin: 0;
}

.calculator__value-number {
  color: #fff;
  margin: 0;
}

@media only screen and (max-width: 810px) {
  .calculator__form-content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .calculator {
    &::before {
      width: 80%;
    }

    &::after {
      width: 20%;
    }
  }

  .calculator__content {
    &::before {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 570px) {
  .calculator {
    &::before {
      width: 100%;
    }

    &::after {
      display: none;
    }
  }

  .calculator__content {
    &::before {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 455px) {
  .calculator__form-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .calculator__btn-wrapper {
    display: grid;
  }

  .calculator__form-wrapper {
    width: 100%;
  }

  .calculator__range {
    width: 100%;
  }

  .calulator__title {
    margin: 0;
    color: #fff;
    font-size: 25px;
    margin-bottom: 100px;
  }
}