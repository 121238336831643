.services {
  padding: 100px 0;
  background-color: #ffffff;
}

.services__tab-wrapper {
  display: flex;
  margin-top: 100px;
  gap: 20px;
}

.services__title {
  text-align: center;
  font-size: 40px;
  margin-bottom: 100px;
  color: #000000;
  position: relative;
  width: 300px;
  margin: 0 auto;

  &::after {
    content: '';
    position: absolute;
    background-color: #FF7A00;
    width: 300px;
    height: 2px;
    bottom: -19px;
    left: 0;
  }
}

.services__tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
  justify-content: space-between;
}

.services__tab {
  padding: 68px 100px;
  text-align: center;
  width: 380px;
  cursor: pointer;
  border: #FF7A00 solid 4px;
  outline: none;
  position: relative;
  margin-bottom: 15px;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.384);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    left: 0;
  }

  &::after {
    content: '';
    position: absolute;
    background: url("../../assets/images/construction5.png") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
  }

  &--construction::after {
    background: url("../../assets/images/construction5.png") center center no-repeat;
  }

  &--interior::after {
    background: url("../../assets/images/interior.webp") center center no-repeat;
  }

  &--project::after {
    background: url("../../assets/images/project-development.webp") center center no-repeat;
  }
}

.services__tab-active::before {
  background-color: rgba(0, 0, 0, 0.836);
}

.services__tab-text {
  position: relative;
  z-index: 9;
  color: #fff;
  font-size: 22px;
  letter-spacing: 2px;
  font-weight: 500;
}

.services__tab-title {
  font-size: 24px;
  color: #F17D11;
  text-align: center;
}

button {
  border: none;

}

.services__tab-contents {
  flex-grow: 1;
  border: 4px solid #F17D11;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.664);
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    background: url("https://images.squarespace-cdn.com/content/v1/596e44d56f4ca36db2444392/1505422718206-S3TIEU2RM4LURTFCWPAX/NORM-LI-AGI-161007_EXTERIOR_HERO_05-1-low.jpg") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0px;
  }
}

.services__tab-content {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
  position: relative;
  z-index: 6;
}

.services__tab-content h2 {
  padding: 0px 0 5px 0px;
}

.services__tab-content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.services__tab-content p {
  width: 100%;
  height: 100%;
  color: #FFF;
  font-size: 18px;
  line-height: 1.8;
}

.services__tab-content-active {
  display: block;
}

@media only screen and (max-width: 900px) {
  .services__tab-wrapper {
    flex-direction: column;
  }

  .services__tabs {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: stretch;
  }

  .services__tab {
    padding: 50px;
    width: 100%;

    &--interior {
      border-left: none;
      border-right: none;
      &::after {
        background: url("../../assets/images/interior.webp") center center no-repeat;
      }
    }
  }

  .services__tab-active::before {
    background-color: rgba(0, 0, 0, 0.836);
  }
}

@media only screen and (max-width: 805px) { 
  .services__tab {
    padding: 10px;
  }

  .services__tab-text {
    font-size: 20px;
    letter-spacing: 1px;
  }

  .services__tab-content p {
    line-height: 1.5;
  }
}

@media only screen and (max-width: 530px) { 
  .services__tab-text {
    font-size: 18px;
    font-weight: 400;
  }

  .services__title{
    font-size: 30px;
  }
}

@media only screen and (max-width: 445px) { 
  .services__tab-text {
    font-size: 16px;
  }

  .services__tab-content h2 {
    font-size: 20px;
  }  

  .services__tab-content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 405px) { 
  .services__tab-text {
    font-size: 14px;
  }
  .services__tab {
    padding: 10px 5px;
  }
}