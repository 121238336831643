@keyframes background {
  0% {
    background-color: #000;
    position: absolute;
    z-index: 5;
  }

  50% {
    background-color: #000;
    position: absolute;
    z-index: 5;
  }

  75% {
    background-color: rgb(0, 0, 0);
    position: absolute;
    z-index: 5;
  }

  100% {
    background-color: rgb(0, 0, 0);
    position: absolute;
    z-index: 5;
    // opacity: 0.3;
  }
}

.type-animation__wrapper {
  background-color: #000;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  animation: ease background 5s;
  display: flex;
  align-items: center;
  transition: 0.4s ease all;
  
}

.type-animation__text {
  color: #FFFFFF;
  font-size: 100px;
  margin: 0 auto;
  display: inline-block;
}

@media only screen and (max-width: 936px) {
  .type-animation__text{
    font-size: 70px;
  }
}

@media only screen and (max-width: 668px) {
  .type-animation__text{
    font-size: 60px;
  }
}

@media only screen and (max-width: 554px) {
  .type-animation__text{
    font-size: 40px;
  }
}

@media only screen and (max-width: 392px) {
  .type-animation__text{
    font-size: 30px;
  }
}