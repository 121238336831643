.site-header {
  background: transparent;
  padding: 15px 0;
  position: fixed;
  z-index: 100;
  margin-top: 5px;
  width: 100%;

}

.site-header__backgorund{
  background-color: #000000; 
  transition: .5s ease all;
}

.site-header__lang-btn {
  display: block;
}

.site-header__btn-icon::after {
  color: #FFFFFF;
}

$speed: 0.7s;

.site-header__number {
  cursor: pointer;
  opacity: 1;
  outline: 0;
  color: #fff;
  line-height: 40px;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  display: block;

  &:hover {

    &:after {
      opacity: 1;
      transform: translateY(0) rotateX(0);
    }

    &:before {
      opacity: 0;
      transform: translateY(50%) rotateX(90deg);
    }
  }

  &:after {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    color: #323237;
    padding: 0px 20px;
    border: none;
    background: #FFFFFF;
    font-size: 0;
    font-weight: 900;
    color: red;
    letter-spacing: 0.8px;
    border-radius: 10px;
    border: 3px solid #FF7A00;
    display: block;
    transition: $speed;
    position: absolute;
    background-image: url("https://img.icons8.com/ios/30/phone--v1.png");
    background-position: center center;
    background-repeat: no-repeat;
    content: attr(data-back);
    transform: translateY(-50%) rotateX(90deg);
  }

  &:before {
    top: 0;
    left: 0;
    opacity: 1;
    color: #adadaf;
    display: block;
    line-height: 40px;
    transition: $speed;
    padding: 0px 20px;
    border: none;
    background: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 0.8px;
    border-radius: 10px;
    border: 3px solid #FF7A00;
    position: relative;
    content: attr(data-front);
    transform: translateY(0) rotateX(0);
  }
}


.site-header__link:after,
.site-header__link:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#FF7A00, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.site-header__link:before {
  top: 2px;

}

.site-header__link:hover:after,
.site-header__link:hover:before {
  backface-visibility: hidden;
  border-color: #FF7A00;
  transition: width 350ms ease-in-out;
  width: 100%;
}

.site-header__link:hover:before {
  bottom: auto;
  top: 2px;
  width: 100%;
}


@media only screen and (max-width: 935px) {
  .site-header__number::before {
    padding: 0 10px;
  }

  .site-header__logo {
    width: 60px;
  }
}
@media only screen and (max-width: 800px) {
  .site-header__number::before {
    padding: 0 10px;
  }

  .site-header__logo {
    width: 60px;
  }
}

@media only screen and (max-width: 760px) {
  .site-header__number::before {
    line-height: 30px;
  }

  .site-header__number::before {
    padding: 0 3px;
  }
}

@media only screen and (max-width: 680px) {
  .site-header__number{
    display: none
  }
}
