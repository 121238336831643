.numbers {
  padding: 100px 0;
  background-color: #ffffff;
  overflow: hidden;
}

.numbers__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.numbers__item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.numbers__item-number {
  margin: 0;
  color: #FF5A30;
  font-size: 46px;
  font-weight: 700;
}

.numbers__item-text {
  margin: 0;
  color: #000000;
  font-size: 20px;
}

@media only screen and (max-width: 1130px) {
  .numbers__item-text {
    width: 171px;
    text-align: center;
  }
}

@media only screen and (max-width: 850px) {
  .numbers__item-text {
    width: 171px;
    text-align: center;
    font-size: 18px;
  }
  
  .numbers__list{
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}

@media only screen and (max-width: 402px) {
  .numbers__list{
    gap: 10px;
  }

  .numbers__item-number {
    font-size: 35px;
    font-weight: 700;
  }  

  .numbers__item-text {
    font-size: 16px;
    width: 150px;
  }
}