.navbar__link:after,
.navbar__link:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#FF7A00, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.navbar__link:before {
  top: 2px;

}

.navbar__link:hover:after,
.navbar__link:hover:before {
  backface-visibility: hidden;
  border-color: #FF7A00;
  transition: width 350ms ease-in-out;
  width: 100%;
}

.navbar__link:hover:before {
  bottom: auto;
  top: 2px;
  width: 100%;
}

.navbar__link-router{
  text-decoration: none;
}