.button {
  padding: 17px 35px;
  border: none;
  background: #1C1D1E;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: 0.8px;
  border-radius: 10px;
  border: 3px solid #FF7A00;
}