.footer {
  padding: 100px 0;
  background-color: #000;
}

.footer__container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;

  &::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #FFF;
    bottom: -20px;
  }
}

.footer__icon {
  width: 30px;
}

.footer__rights{
  margin: 0;
  color: #FFF;
  font-size: 20px;
  margin-top: 30px;
}