.faq {
  padding: 100px 0;
  background-color: #000;
  overflow: hidden;
}

.faq__title {
  text-align: center;
  font-size: 40px;
  color: #FFF;
  margin-bottom: 100px;
}

.faq__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.faq__item {
  border-radius: 20px;
  border: 2px solid #fd7a00;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: .3s all ease;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    transform: scale(1.03);
  }
}

.faq__item-btn-wrapper {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  align-items: center;
}

.faq__item-title {
  margin: 0;
  color: #FFF;
  font-size: 25px;
}

@keyframes textanimation {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.faq__item-text,
.faq__item-text--opened {
  margin: 0;
  color: #FFF;
  display: none;
  // transition: .3s all ease;
  margin-top: 20px;
  font-size: 18px;
}

.faq__item-text--opened {
  animation: 0.6s ease textanimation;
  display: block;
}



.faq__btn,
.faq__btn-opened {
  border: none;
  background-color: transparent;
  width: 30px;
  height: 30px;
  position: relative;
  

  &::before {
    content: '';
    position: absolute;
    background: url("../../assets/icons/plus.svg") center center no-repeat;
    background-size: cover;
    width: 30px;
    height: 30px;
    background-size: cover;
    top: 0;
  }
}

@keyframes btnanimation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(180deg)
  }
}

.faq__btn-opened {
  &::before {
    content: '';
    position: absolute;
    background: url("../../assets/icons/plus.svg") center center;
    width: 30px;
    height: 30px;
    background-size: cover;
    // transform: rotate(45deg);
    animation: 0.9s ease btnanimation;
  }
}

@media only screen and (max-width: 830px) {
  .faq__item-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 570px) {
  .faq__item-title {
    font-size: 18px;
  }

  .faq__title {
    font-size: 30px;
  }

  .faq__item-text {
    font-size: 16px;
  }

  .faq__item {
    padding: 10px;
  }
}