.benefits {
  padding: 100px 0;
}

.benefits__content {}

.benefits__title {
  margin: 0;
  margin-bottom: 100px;
  text-align: center;
  font-size: 40px;
}

.benefits__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.benefits__item {
  display: flex;
  align-items: center;
  gap: 140px;

  &:nth-child(2n) {
    flex-direction: row-reverse;
    margin: 50px 0;
  }
}

.benefits__item-title {
  margin: 0;
  margin-bottom: 20px;
}

.benefits__text {
  margin: 0;
}

.benefits__img {
  width: 500px;
  border-radius: 10px;
  animation: heartbeat 4s infinite;
}

.benefits__img-quality{
  border-radius: 10px;
  width: 500px;
}

@keyframes heartbeat {
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}

@media only screen and (max-width: 1070px) {
  .benefits__item {
    gap: 100px;
  }

  .benefits__text--cost {
    line-height: 1.4;
    font-size: 15px;
  }

  .benefits__img,
  .benefits__img-quality {
    width: 450px
  }
}

@media only screen and (max-width: 950px) {
  .benefits__item {
    gap: 70px;
  }
}

@media only screen and (max-width: 830px) {
  .benefits__item {
    gap: 50px;
  }

  .benefits__text {
    font-size: 15px;
    line-height: 1.3;
  }

  .benefits__item-title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  .benefits__img,
  .benefits__img-quality {
    width: 350px
  }
}

@media only screen and (max-width: 700px) {
  .benefits__item {
    gap: 30px;
  }

  .benefits__text {
    font-size: 12px;
    line-height: 1.3;
  }

  .benefits__img,
  .benefits__img-quality {
    width: 270px
  }

  .benefits__item-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 620px) {
  .benefits__item {
    flex-direction: column;

    &:nth-child(2n) {
      flex-direction: column;
    }
  }



  .benefits__text {
    text-align: center;
    padding: 0 50px;
  }

  .benefits__item-title {
    text-align: center;
  }

  .benefits__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 450px) {
  .benefits__title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 370px) {
  .benefits__text {
    padding: 0 20px;
  }
}