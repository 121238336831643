.visit-us {
  padding: 100px 0;
  padding-top: 0;
}

.visit-us__container {}

.visit-us__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.visit-us__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  border-radius: 20px;
  transition: 0.3s ease all;
  padding: 20px 10px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px, rgba(0, 0, 0, 0.3) 10px 10px, rgba(85, 95, 149, 0.2) 15px 15px, rgba(0, 0, 0, 0.1) 20px 20px, rgba(85, 95, 149, 0.05) 25px 25px;
    background-color: #fafafa;
  }
}

.visit-us__img {
  width: 40px;
}

.visit-us__item-title {
  margin: 0;
  font-size: 22px;
  margin: 30px 0;
  cursor: pointer;
  text-align: center;
}

.visit-us__item-desc {
  margin: 0;
  text-align: center;
}

.contact-us__map{
  width: 100%;
  height: 300px;
}

.visit-us__item-badge{
  margin: 0;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  padding: 5px 8px;
  margin-top: 20px;
  font-weight: 500;
}

.visit-us__item-badge > a {
  text-decoration: none;
  color: #fff;
}

.contact__form-wrapper{
  position: relative;
 background-color: #FFFFFF;
 padding: 30px;
 border-radius: 20px;
 max-width: 800px;
 margin: 0 auto;
 transform: translateY(-120px);
 z-index: 3;
}

@media only screen and (max-width: 1130px) {
  .visit-us__list{
    gap: 20px;
  }
}

@media only screen and (max-width: 880px) {
  .visit-us__list{
    gap: 20px;
  }
  .visit-us__item{
    width: 280px;
  }
}

@media only screen and (max-width: 750px) {
  .visit-us__item-title{
    font-size: 20px;
    margin: 20px 0;
  }

  .visit-us__item-desc{
    line-height: 1.3;
    font-size: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .visit-us__list{
    flex-direction: column;
    align-items: center;
  }

  .visit-us__item-desc{
    line-height: 1.6;
    font-size: 16px;
  }
}